<template>
  <div class="footer">
    © {{ currentYear }} Lein Games. All rights Reserved.
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }

}
</script>

<style scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 114px;
  background: #278EDA;

  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
}
</style>
