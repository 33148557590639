<template>
  <div class="content">
    <div class="text-block">
      <div class="title">
        Bringing<br>
  the Thunder
      </div>
      <div class="text">
        We at Lein Games strives for greatness, we specialized in game development and total solution services. Lein games always focuses on the most important, like the lightening in our logo, we shine bright and sound loud for our players.
      </div>
    </div>
    <img src="../assets/leingames_image.png" alt="" srcset="">
  </div>
</template>

<script>
export default {
  name: 'TheContent',
}
</script>

<style lang="scss" scoped>
.content {  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 80px;

  @media only screen and (max-width: 1300px) {
    flex-direction: column-reverse;
    padding: 110px 20px;
  }

  .text-block {
    margin-right: 80px;

    @media only screen and (max-width: 1300px) {
      margin: 0;
    }
  }
  .title {
    text-align: left;
    font-weight: 700;
    font-size: 94px;
    line-height: 1.42;
    color: #404041;
    margin-bottom: 25px;

    @media only screen and (min-width: 751px) and (max-width: 1300px) {
      margin-top: 80px;
      font-size: 80px;
    }

    @media only screen and (max-width: 750px) {
      margin-top: 40px;
      font-size: 40px;    }
  }

  .text {
    text-align: left;
    max-width: 800px;
    font-weight: 400;
    font-size: 25px;
    line-height: 1.76;
    letter-spacing: 0.445833px;
    color: #868686;

    @media only screen and (max-width: 750px) {
      max-width: 100%;
    }

  }

  img {
    max-width: 100%;
  }
}
</style>
